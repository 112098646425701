<template>
    <div class="advert">
        <div class="content">
            <div class="logo"><img src="../assets/images/swift-plus-white.png" /></div>
            <div class="info">
                <div class="text">Your complete solution to go digital, at no additional cost</div>
                <div class="button"><a href="https://swiftdental.co.uk/wp-content/uploads/2023/05/Swift-Dental_A5-Leaflet.pdf" target="_blank">Find out more</a></div>
            </div>
        </div>
    </div>
</template>

<style scoped lang="scss">
    .advert
    {
        background: url('../assets/images/swift-background.jpg');
        background-size: cover;
        background-position: center left;
        padding: 30px;
        display: flex;
        align-items: center;

        .logo
        {
            color: $white;
            font-size: 2em;
            line-height: 1;
            margin-bottom: 30px;
            
            img
            {
                width: 200px;
            }
        }

        .text
        {
            font-size: 2.2em;
            color: $white;
            line-height: 1.1em;
            margin-bottom: 30px;
        }

        .button a
        {
            background: $swift-yellow;
            color: $swift-not-black;
            white-space: nowrap;
            font-size: 1em;
            display: inline-block;
            padding: 0.5em 1.5em;
            border-radius: 100px;
            text-decoration: none;
        }

        @include media-breakpoint-up(xl)
        {
            // min-height: 300px;
            padding: 40px;

            .text
            {
                font-size: 2.2em;
                font-size: 1.6em;
                margin-bottom: 0px;
                margin-right: 40px;
            }

            .info
            {
                display: flex;
            }

            .button
            {
                padding-top: 0.5em;

                a
                {
                    font-size: 1.1em;
                }
            }
            
        }
    }
</style>
